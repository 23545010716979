<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full fixed md:static"
		>
			<span class="text-white ml-2 font-bold">Resumen</span>
			<div>
				<span class="text-white font-bold">Filtrar por estado: </span>
				<select
					v-model="filtroTemp"
					class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none font-bold"
				>
					<option :value="null" selected>Todos</option>
					<option value="asignado">Asignado</option>
					<option value="reasignado">Reasignado</option>
					<option value="espera">Espera</option>
					<option value="ejecucion">Ejecución</option>
					<option value="completado">Completado</option>
					<option value="finalizado">Finalizado</option>
					<option value="entregado">Entregado</option>
					<option value="anulado">Anulado</option>
				</select>
			</div>
			<div>
				<span class="text-white font-bold">Filtrar por cobrado: </span>
				<select
					v-model="filtroCobradoTemp"
					class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none font-bold"
				>
					<option :value="null" selected>Todos</option>
					<option value="1">Cobrados</option>
					<option value="0">No cobrados</option>
				</select>
			</div>
			<div class="flex items-center justify-center">
				<router-link to="/Cotizar">
					<button
						@click="$emit('click')"
						class="text-white font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="text-white underline">Realizar cotización</span>
					</button>
				</router-link>
			</div>
		</div>
		<div class="md:flex md:justify-between md:mt-0 mt-20">
			<div
				class="w-full md:w-2/6 md:ml-5 border-gray-300 border h-32 rounded-lg grid items-center"
			>
				<div class="flex md:ml-12 ml-2">
					<div>
						<span class="font-bold mr-2">Fecha Inicio:</span>
						<input
							class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							type="date"
							v-model="fechaInicioTemp"
						/>
					</div>
					<div class="w-36">
						<span class="font-bold mx-2">Fecha Fin:</span>
						<input
							class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							type="date"
							v-model="fechaFinTemp"
						/>
					</div>
				</div>
				<div class="flex justify-around">
					<Botonp
						class="underline"
						botonpText="Reset"
						@click="resetData()"
					></Botonp>
					<Botonp
						class="underline"
						botonpText="Buscar"
						@click="nuevaBusqueda()"
					></Botonp>
				</div>
			</div>

			<div class="flex ml-3">
				<div v-if="mostrarCobradoTotal">
					<div
						class="flex flex-wrap content-center justify-end mt-20 md:mt-4 mb-4"
					>
						<div class="flex justify-center content-center">
							<div class="mr-3 flex content-center flex-wrap">
								<div class="">Total cobrado USD:</div>
							</div>
							<Campo
								:campoCoti="formato(comprasResumenTotal.total_cobrado_usd)"
								class="mr-10"
							/>
						</div>
					</div>
					<div
						class="flex flex-wrap content-center justify-end mt-20 md:mt-4 mb-4"
					>
						<div class="flex justify-center content-center">
							<div class="mr-3 flex content-center flex-wrap">
								<div class="">Total cobrado Bs:</div>
							</div>
							<Campo
								:campoCoti="formato(comprasResumenTotal.total_cobrado_bs)"
								class="mr-10"
							/>
						</div>
					</div>
				</div>
				<div>
					<div
						class="flex flex-wrap content-center justify-end mt-20 md:mt-4 mb-4"
					>
						<div class="flex justify-center content-center">
							<div class="mr-3 flex content-center flex-wrap">
								<div class="">Total por cobrar USD:</div>
							</div>
							<Campo
								:campoCoti="formato(comprasResumenTotal.total_usd)"
								class="mr-10"
							/>
						</div>
					</div>
					<div
						class="flex flex-wrap content-center justify-end mt-20 md:mt-4 mb-4"
					>
						<div class="flex justify-center content-center">
							<div class="mr-3 flex content-center flex-wrap">
								<div class="">Total por cobrar Bs:</div>
							</div>
							<Campo
								:campoCoti="formato(comprasResumenTotal.total_bs)"
								class="mr-10"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex justify-center my-4">
			<span v-if="showMessage">{{ message }}</span>
		</div>
		<div class="flex justify-center mx-6">
			<table id="pedidos" class="table-auto">
				<thead>
					<tr>
						<th class="border-2 px-2 py-2"># Orden de compra</th>
						<th class="border-2 px-4 py-2">Cliente</th>
						<th class="border-2 px-4 py-2">Teléfono</th>
						<th class="border-2 px-4 py-2">Vendedor</th>
						<th class="border-2 px-4 py-2">Total Bs</th>
						<th class="border-2 px-4 py-2">Total $</th>
						<th class="border-2 px-4 py-2">Fecha Entrega</th>
						<th class="border-2 px-4 py-2">Tiempo estimado</th>
						<th class="border-2 px-4 py-2">Estado</th>
						<th class="border-2 px-4 py-2">Cobrado</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(pedido, k) in comprasResumenTotal.array_compras" :key="k">
						<td class="text-center border-2 px-2 py-4">
							<router-link
								class="hover:text-blue-500 hover:underline"
								:to="{
									name: 'PedidoID',
									params: {
										id: pedido.id,
										nombre: pedido.cliente_nombre,
										telefono: pedido.cliente_telefono,
										estatus: pedido.estatus,
										cobrado: pedido.cobrado
									}
								}"
							>
								<div>{{ pedido.id.padStart(4, "0") }}</div></router-link
							>
						</td>
						<td class="border-2 px-4 py-4">
							{{ firstLetterUpperCase(pedido.cliente_nombre) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ pedido.cliente_telefono }}
						</td>
						<td class="border-2 px-4 py-1">
							{{ firstLetterUpperCase(pedido.vendedor_name) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(pedido.total_factura * pedido.tasa_dia) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							{{ formato(pedido.total_factura) }}
						</td>
						<td class="border-2 px-2 py-4 text-center">
							{{ formatoFecha(pedido.fecha_entrega) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							{{ formatoHora(pedido.tiempo_estimado) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							{{ firstLetterUpperCase(pedido.estatus) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							<div
								class="w-20"
								type="text"
								:class="pedido.cobrado == 1 ? 'text-green-400' : 'text-red-400'"
							>
								<div v-if="pedido.cobrado == 1">Cobrado</div>
								<div v-else>No cobrado</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex justify-between">
			<div class="pl-32 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="dataInicio > 0"
					@click="cargarDataAnterior()"
				>
					Anterior
				</button>
			</div>
			<div class="pr-40 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="comprasResumenTotal.continua_data"
					@click="cargarDataSiguiente()"
				>
					Siguiente
				</button>
			</div>
		</div>
		<div class="flex justify-center mb-4">
			<span v-if="showMessage">{{ message }}</span>
		</div>
		<div class="flex items-center justify-center w-full my-10">
			<router-link to="/Cotizar">
				<Botonp class="underline mr-10" botonpText="Realizar Cotizacion">
					<svg
						id="Capa_1"
						enable-background="new 0 0 551.13 551.13"
						height="512"
						viewBox="0 0 551.13 551.13"
						width="512"
						class="h-6 w-6 mr-2 fill-current text-botonT"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
						/>
						<path
							d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
						/>
					</svg>
				</Botonp>
			</router-link>
			<router-link to="/Utilidades"
				><Botons class="underline" botonsText="Utilidades"
			/></router-link>
		</div>
	</div>
</template>

<script>
import Botonp from "@/components/botonPrimario";
import Botons from "@/components/botonSecundario";
import Campo from "@/components/camposCotizacion";
import {
	formato,
	firstLetterUpperCase,
	formatoFecha,
	formatoHora
} from "@/functions.js";

export default {
	name: "Pedidos",

	components: {
		Botonp,
		Botons,
		Campo
	},
	data() {
		return {
			dataInicio: 0,
			dataFin: 20,
			filtro: null,
			fechaInicio: null,
			fechaFin: null,
			filtroCobrado: null,
			filtroTemp: null,
			fechaInicioTemp: null,
			fechaFinTemp: null,
			filtroCobradoTemp: null,
			comprasResumenTotal: {},
			message: "",
			showMessage: false,
			mostrarCobradoTotal: false
		};
	},
	/*   async mounted() {
      this.$store.state.isLoading = true;
      await this.queryCuentasCobrar();
      this.$store.state.isLoading = false;
    }, */
	apollo: {
		comprasResumenTotal: {
			query: require("@/graphql/queries/pedidos.gql"),
			// Reactive parameters
			variables() {
				// Use vue reactive properties here
				return {
					dataInicio: this.dataInicio,
					dataFin: this.dataFin,
					filtro: this.filtro,
					fechaInicio: this.fechaInicio,
					fechaFin: this.fechaFin,
					filtroCobrado: this.filtroCobrado
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.comprasResumenTotal,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	methods: {
		formato,
		firstLetterUpperCase,
		formatoFecha,
		formatoHora,
		resetData() {
			this.dataInicio = 0;
			this.dataFin = 20;
			this.fechaInicioTemp = null;
			this.fechaFinTemp = null;
			this.mostrarCobradoTotal = false;
		},
		nuevaBusqueda() {
			//Validacion de data
			let fechaInicio = new Date(this.fechaInicioTemp);
			let fechaFin = new Date(this.fechaFinTemp);
			let ejecutarQuery = false;

			if (fechaInicio > fechaFin) {
				alert("La fecha de inicio tiene que ser antes de la fecha de fin");
			} else {
				if (this.fechaInicioTemp === null && this.fechaFinTemp === null) {
					this.comprasResumenTotal = {};
					ejecutarQuery = true;
					this.mostrarCobradoTotal = false;
				} else {
					if (this.fechaInicioTemp === null || this.fechaFinTemp === null) {
						alert("Por favor elegir fecha de inicio y fin.");
					} else {
						ejecutarQuery = true;
						this.mostrarCobradoTotal = true;
					}
				}
			}
			if (ejecutarQuery) {
				this.dataInicio = 0;
				this.dataFin = 20;
				this.filtro = this.filtroTemp;
				this.fechaInicio = this.fechaInicioTemp;
				this.fechaFin = this.fechaFinTemp;
				this.filtroCobrado = this.filtroCobradoTemp;
			}
		},
		async queryCuentasCobrar() {
			this.message = "Cargando, por favor espere";
			this.showMessage = true;
			this.$store.state.isLoading = true;
			await this.$apollo.watchQuery({
				query: require("@/graphql/queries/pedidos.gql"),
				variables: {
					dataInicio: this.dataInicio,
					dataFin: this.dataFin,
					filtro: this.filtro,
					fechaInicio: this.fechaInicio,
					fechaFin: this.fechaFin,
					filtroCobrado: this.filtroCobrado
				},
				fetchPolicy: "cache-and-network",
				update: data => data.comprasResumenTotal,
				error: (error, vm) => (vm.feedback.errorSearched = true),
				watchLoading(isLoading, countModifier) {
					// isLoading is a boolean
					// countModifier is either 1 or -1
					this.$store.state.isLoading = isLoading;
				}
			});
		},
		cargarDataAnterior() {
			this.dataInicio -= 20;
			this.dataFin -= 20;
			this.queryCuentasCobrar();
		},
		cargarDataSiguiente() {
			this.dataInicio += 20;
			this.dataFin += 20;
			this.queryCuentasCobrar();
		}
	}
	/* computed: {
    ...mapState["titulo"]
  } */
};
</script>
